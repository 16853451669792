html {
  overflow: hidden;
}

html,
body,
#root,
#swipeableViewsParentContainer,
.react-swipeable-view-container,
.swipeablePage {
  height: 100% !important;
  text-align: center;
}

body {
  /* background-image: url("../img/htmlback.jpg"); */
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', "Great Vibes",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  max-width: 464px;
  max-height: 850px;

  /* border: 10px solid silver; */
  border-radius: 15px;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


#preweddingvideo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  color: #fff;
  z-index: -2;
}

#startPage {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 100;
  color: #fff;
  top: 0;
  background-color: black;
  opacity: 0.7;
  visibility: visible;
  background-image: url('../public/img/HomePage.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

#startPageBtn {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  width: 180px;
  height: 180px;
  border: 3px solid silver;
  border-radius: 90px;
  box-shadow: 0 0 20px 0px;

}

#SwipeableViews {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: black;
  opacity: 0.7;
  visibility: hidden;
}

#slide1 {
  border-radius: 20px;
  color: #fff;
  font-family: 'Lora', serif;
  background-image: url('../public/img/Page1.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  
  
}

#slide2 {
  border-radius: 20px;
  color: #fff;
  font-family: 'Lora', serif;
  background-image: url('../public/img/Page2.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  
}

#slide3 {
  border-radius: 20px;
  color: #fff;
  font-family: 'Lora', serif;
  background-image: url('../public/img/Page3.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

#slide4 {
  border-radius: 20px;
  color: #fff;
  font-family: 'Lora', serif;
  background-image: url('../public/img/Page4.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

#slide5 {
  border-radius: 20px;
  color: #fff;
  font-family: 'Lora', serif;
  background-image: url('../public/img/Page5.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

#slide6 {
  border-radius: 20px;
  color: #fff;
  font-family: 'Lora', serif;
  background-image: url('../public/img/Page6.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

#slide7 {
  border-radius: 20px;
  color: #fff;
  font-family: 'Lora', serif;
  background-image: url('../public/img/Page3.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

#slide8 {
  border-radius: 20px;
  color: #fff;
  font-family: 'Lora', serif;
  background-image: url('../public/img/Page4.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
#lastPage {
  border-radius: 20px;
  color: #fff;
  font-family: 'Lora', serif;
  background-image: url('../public/img/LastPage.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

#slide1Header,
#slide2Header,
#slide3Header,
#slide4Header,
#slide5Header,
#slide6Header ,
#slide7Header ,
#slide8Header {
  height: 10%;
  font-family: 'Great Vibes', cursive;
  font-size: 40px;
}

#slide1Body,
#slide2Body,
#slide3Body,
#slide4Body,
#slide5Body,
#slide6Body,
#slide7Body,
#slide8Body {
  height: 83%;
}

#slide1Body {
  font-family: 'Great Vibes', cursive;
  font-size: 50px;
}

#slide1Body>div:first-child {
  padding-top: 50%;

}

#slide1Footer,
#slide2Footer,
#slide3Footer,
#slide4Footer,
#slide5Footer,
#slide6Footer,
#slide7Footer,
#slide8Footer {
  position: relative;
  height: 6%;
  text-align: center;
}

.arrow {
  position: relative;
  left: 49%;
  width: 20px;
  transform: translate(-50%, -50%);
  transform: rotate(90deg);
  cursor: pointer;
}

.arrow span {
  display: block;
  width: 6px;
  height: 6px;
  border-bottom: 3px solid white;
  border-right: 3px solid white;
  transform: rotate(45deg);
  animation: animate 2s infinite;
}

.arrow span:nth-child(2) {
  animation-delay: -0.2s;
}

.arrow span:nth-child(3) {
  animation-delay: -0.4s;
}

@keyframes animate {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-20px, -20px);
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: rotate(45deg) translate(20px, 20px);
  }
}

#muteUnmute {
  z-index: 101;
  font-size: 25px;
  position: absolute;
  bottom: 15px;
  left: 15px;
  cursor: pointer;
}

.attendingPart {
  width: 250px;
  margin: 0 auto;
  height: 40px;
  border: 2px solid #fff;
  background: rgba(72, 72, 72, 0.4);
  border-radius: 5px;
  color: #fff;
  font-size: 18px;
  padding: 0 15px;
  box-sizing: border-box;
  outline: none;
}
#leafContainer {
  width: 100%;
  height: 100vh;
  position: fixed;
  overflow: hidden;
  pointer-events: none;
  z-index: 175;
}

#leafContainer > div 
{
    position: absolute;
    width: 45px;
    height: 45px;
    -webkit-animation-iteration-count: infinite, infinite;
    -webkit-animation-direction: normal, normal;
    -webkit-animation-timing-function: linear, ease-in;
}

#leafContainer.floral_white > div,
#leafContainer.floral > div,
#leafContainer.floral > div img,
#leafContainer.floral_white > div img{
	
    width: auto;
    height: 17px;
}


#leafContainer > div > img {
     position: absolute;
     width: auto;
     height: 55px;
     -webkit-animation-iteration-count: infinite;
     -webkit-animation-direction: alternate;
     -webkit-animation-timing-function: linear;
     -webkit-transform-origin: 50% -100%;
}
#leafContainer.floral > div > img,
leafContainer.floral_white > div > img{
     height: 17px;
}
#leafContainer.hearts_red > div > img,
#leafContainer.hearts_pink > div > img {
     height: 45px;
}
#leafContainer.bigLeaf > div > img {
     height: 65px;
}
#leafContainer.baloons > div > img {
    height: auto;
	width: 35px;
}


@keyframes fade
{
    0%   {/* opacity: 1; */}
    85%  {/* opacity: 1; */}
    90% {/* opacity: 0; */}
}
@keyframes drop
{
    0%   {transform: translate(0px, 50px);}
    100% {transform: translate(0px, -1950px);}
}
@keyframes clockwiseSpin
{
    0%   { transform: rotate(-50deg); }
    60% { transform: rotate(50deg); }
    80% { transform: rotate(80deg); }
   100% { transform: rotate(-40deg); }
}
@keyframes counterclockwiseSpinAndFlip 
{
    0%   { transform: scale(-1, 1) rotate(80deg); }
    100% { transform: scale(-1, 1) rotate(-80deg); }
}

@keyframes clockwiseSpin_baloon
{
    0%   { transform: rotate(-20deg); }
    60% { transform: rotate(20deg); }
    80% { transform: rotate(30deg); }
   100% { transform: rotate(-20deg); }
}
@keyframes counterclockwiseSpinAndFlip_baloon 
{
    0%   { transform: scale(-1, 1) rotate(25deg); }
    100% { transform: scale(-1, 1) rotate(-25deg); }
}

.animated-text {
  font-family: 'Lora', serif;
  font-size: 30px;
  animation: slideIn 2s ease-in-out infinite; /* Adjust animation duration and timing as needed */
}

@keyframes slideIn {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translateX(0);
    opacity: 0;
  }
}